import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { variant } from 'styled-system';

import { Box } from '@/components/box';
import { Button } from '@/components/button';
import { Container } from '@/components/container';
import { Flex } from '@/components/flex';
import { Text } from '@/components/text';
import { colors } from '@/theme/constants/colors';
import { DatoPreviewStatusInterface } from '@/types/cms-types';
import { media } from '@/utils/media';

const variants = variant({
  scale: 'datoPreviewStatus',
  variants: {
    connecting: {
      backgroundColor: 'grey.800',
      color: 'white',
    },
    connected: {
      ...colors.states.success,
      p: {
        fontWeight: 'bold',
      },
    },
    error: {
      ...colors.states.error,
    },
  },
});

export const statusMessage = {
  connecting: 'Connecting to DatoCMS...',
  connected: 'Connected to DatoCMS, receiving live updates',
  closed: 'Connection closed', // This will never be shown
};

const DatoPreviewStatus = ({ status, error, ...props }: DatoPreviewStatusInterface) => {
  if ((!status && !error) || (status === 'closed' && !error)) {
    return null;
  }

  return (
    <Wrapper variant={error ? 'error' : status} {...props}>
      <Container>
        <Flex alignItems="center">
          <Box>
            {status && (
              <Text variant="secondary" className="m-0 leading-normal">
                {statusMessage[status]}
              </Text>
            )}
            {error && (
              <div>
                <Text variant="secondary" className="leading-normal">
                  Error: {error.code}, {error.message}
                </Text>
                {error.response && <pre>{JSON.stringify(error.response, null, 2)}</pre>}
              </div>
            )}
          </Box>

          <Flex ml="auto" justifyContent="flex-end">
            <Button variant="secondaryLight" href="/api/exit-preview" size="small">
              Exit Preview Mode
            </Button>
          </Flex>
        </Flex>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(Flex).attrs({ as: 'aside' })`
  ${variants};
  padding: ${themeGet('space.base')} 0;
  transition: background-color 0.2s ease-in-out;
  width: 100%;

  *:last-child {
    margin-bottom: 0;
  }

  ${media.untilMd} {
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 100;
  }
`;

export { DatoPreviewStatus };
