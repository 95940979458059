import React from 'react';
import { useRecoilValue } from 'recoil';

import { navIsOpenState } from '@/state/navigation';
import { tv } from '@/utils/styles';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const Main = ({ children }: Props) => {
  const navIsOpen = useRecoilValue(navIsOpenState);

  const { base, bodyOverlay } = wrapperStyles({
    navIsOpen,
  });

  return (
    <main className={base()}>
      {children}
      <span className={bodyOverlay()} />
    </main>
  );
};

const wrapperStyles = tv({
  slots: {
    base: '[&>footer]:ease-in-out [&_.blocks]:ease-in-out',
    bodyOverlay: 'body-overlay',
  },
  variants: {
    navIsOpen: {
      true: {
        base: '[&>footer]:max-md:transform-gpu [&>footer]:max-md:blur-[2px] [&>footer]:max-md:backface-hidden [&_#link-bank]:max-md:transform-gpu [&_#link-bank]:max-md:blur-[2px] [&_#link-bank]:max-md:backface-hidden [&_.blocks]:max-md:transform-gpu [&_.blocks]:max-md:blur-[2px] [&_.blocks]:max-md:backface-hidden',
        bodyOverlay: '!pointer-events-auto !opacity-100',
      },
    },
  },
});

export { Main };
