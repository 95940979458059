import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { isEmpty, kebabCase, some } from 'lodash';
import NextLink from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { Fragment, useEffect } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useRecoilState } from 'recoil';

import { Container } from '@/components/container';
import { Heading } from '@/components/heading';
import { Icon } from '@/components/icon';
import { Link } from '@/components/link';
import { Logo } from '@/components/logo';
import { NavToggle } from '@/components/nav-toggle';
import { Text } from '@/components/text';
import { UserNav } from '@/components/user-nav';
import { headerNav } from '@/lib/constants/marketing-site';
import { navIsOpenState } from '@/state/navigation';
import { tv } from '@/utils/styles';

export interface PageHeaderProps {
  variant?: 'light' | 'dark';
}

const PageHeader = ({ variant: headerVariant = 'light' }: PageHeaderProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [navIsOpen, setNavIsOpen] = useRecoilState(navIsOpenState);

  useEffect(() => {
    // Any time the route changes, close nav
    setNavIsOpen(false);
  }, [pathname, searchParams]);

  const ref = useOnclickOutside(() => {
    setNavIsOpen(false);
  });

  const {
    base,
    logoLink,
    wrapper,
    navMenuRoot,
    navMenuList,
    navMenuParentListItemDesktop,
    navMenuParentListItemMobile,
    navMenuContent,
    nestedList,
    subLink,
    subLinkBg,
    indicatorWrapper,
    indicator,
    navViewportPosition,
    navViewport,
    icon,
    navLink,
    navLinkBg,
    navLinkInner,
  } = styles({
    variant: headerVariant,
    navIsOpen,
  });

  return (
    <header className={base()} ref={ref}>
      <Container>
        <div className={wrapper()}>
          <Link href="/" className={logoLink()}>
            <Logo variant="colored" />
          </Link>

          <NavigationMenu.Root delayDuration={100} className={navMenuRoot()}>
            <NavigationMenu.List className={navMenuList()}>
              {headerNav.map((item) => {
                if (!isEmpty(item.children)) {
                  const anyChildActive = some(item.children, { url: pathname });

                  return (
                    <Fragment key={`${kebabCase(item.label)}-parent-nav`}>
                      {/* Desktop only */}
                      <NavigationMenu.Item className={navMenuParentListItemDesktop()}>
                        <NavigationMenu.Trigger
                          onPointerMove={(event) => event.preventDefault()}
                          onPointerLeave={(event) => event.preventDefault()}
                          className={navLink({ navLinkIsActive: anyChildActive })}
                          data-cy="marketing-nav-subnav-trigger"
                        >
                          <span className={navLinkInner()}>
                            {item.label}
                            <Icon name="down" size="base" className={icon({ className: 'ml-1' })} />
                          </span>
                          <span className={navLinkBg({ navLinkIsActive: anyChildActive })} />
                        </NavigationMenu.Trigger>
                        <NavigationMenu.Content className={navMenuContent()} data-cy="marketing-nav-subnav">
                          <ul className={nestedList({ className: item.className })}>
                            {item.children &&
                              item.children.map((child) => (
                                <NavigationMenu.Item key={`${kebabCase(child.label)}-child-nav`}>
                                  <NavigationMenu.Link asChild>
                                    <NextLink
                                      href={child.url}
                                      className={subLink({ subLinkIsActive: pathname === child.url })}
                                      data-cy="marketing-nav-subnav-link"
                                    >
                                      <Heading variant="h5" as="h3" className="mb-xs text-text-primary">
                                        {child.label}
                                      </Heading>
                                      {child.description && (
                                        <Text variant="secondary" className="m-0 text-grey-500">
                                          {child.description}
                                        </Text>
                                      )}
                                      <span className={subLinkBg()} />
                                    </NextLink>
                                  </NavigationMenu.Link>
                                </NavigationMenu.Item>
                              ))}
                          </ul>
                        </NavigationMenu.Content>
                      </NavigationMenu.Item>

                      {/* Mobile, needed as Radix navigation doesn't play well on mobile
                       for sub navs due to the container being outside the list for the
                       style we want (NavigationMenuViewport) */}
                      <NavigationMenu.Item
                        key={`${kebabCase(item.label)}-parent-nav-mobile`}
                        className={navMenuParentListItemMobile()}
                      >
                        <div className={navLink({ navLinkIsActive: pathname === item.url })}>
                          {item.label}

                          <Icon name="down" color="blue.600" size="22px" className="md:none relative left-1 mr-5" />
                        </div>

                        <ul className={nestedList()}>
                          {item.children &&
                            item.children.map((child) => {
                              return (
                                <NavigationMenu.Item key={`${kebabCase(child.label)}-child-nav-mobile`}>
                                  <NavigationMenu.Link asChild>
                                    <NextLink
                                      href={child.url}
                                      className={subLink({ subLinkIsActive: pathname === child.url })}
                                    >
                                      <Text as="span" variant="secondary" className="mb-0 text-text-primary">
                                        {child.label}
                                      </Text>
                                      <span className={subLinkBg()} />
                                    </NextLink>
                                  </NavigationMenu.Link>
                                </NavigationMenu.Item>
                              );
                            })}
                        </ul>
                      </NavigationMenu.Item>
                    </Fragment>
                  );
                }

                return (
                  <NavigationMenu.Item
                    key={`${kebabCase(item.label)}-nav`}
                    className={navMenuParentListItemMobile({ className: 'md:block' })}
                  >
                    <NavigationMenu.Link active={pathname === item.url} asChild>
                      <Link href={item.url} className={navLink({ navLinkIsActive: pathname === item.url })}>
                        <span className={navLinkInner()}>{item.label}</span>
                        <span className={navLinkBg({ navLinkIsActive: pathname === item.url })} />
                      </Link>
                    </NavigationMenu.Link>
                  </NavigationMenu.Item>
                );
              })}

              <NavigationMenu.Indicator className={indicatorWrapper()}>
                <div className={indicator()} />
              </NavigationMenu.Indicator>
            </NavigationMenu.List>

            <UserNav variant={headerVariant} hideUsageOnSmallScreens />

            <div className={navViewportPosition()}>
              <NavigationMenu.Viewport className={navViewport()} />
            </div>
          </NavigationMenu.Root>
          <NavToggle variant={headerVariant} />
        </div>
      </Container>
    </header>
  );
};

const styles = tv({
  slots: {
    base: 'absolute z-5 w-full',
    logoLink:
      'logo-link [&_svg_path]:delay-[0.12s] relative z-5 mr-8 flex items-center [&_svg_path]:transition-all [&_svg_path]:duration-300 [&_svg_path]:ease-in-out',
    wrapper: 'flex items-center justify-between pb-6 pt-5 md:grid md:grid-cols-[min-content,1fr,min-content] lg:pt-8',
    navMenuRoot:
      'relative ml-auto flex max-md:absolute max-md:left-0 max-md:top-0 max-md:w-full max-md:-translate-y-full max-md:flex-col max-md:bg-grey-100 max-md:p-4 max-md:pt-20 max-md:[transition:_transform_0.35s_ease-in-out,box-shadow_0.2s_ease-in-out_0.2s] md:justify-end lg:pl-[210px]',
    navMenuList:
      'flex items-center justify-center max-md:w-full max-md:flex-col max-md:opacity-0 max-md:transition-opacity max-md:delay-0 max-md:ease-in-out md:gap-1',
    navMenuParentListItemDesktop: 'hidden md:block',
    navMenuParentListItemMobile:
      'block max-md:w-full max-md:border-b max-md:border-grey-200 max-md:pl-2 max-md:last:mb-0 max-md:last:border-b-0 md:hidden',
    navMenuContent:
      'z-1 sm:w-auto md:absolute md:left-0 md:top-0 md:data-[motion=from-end]:animate-enter-from-right md:data-[motion=from-start]:animate-enter-from-left md:data-[motion=to-end]:animate-exit-to-right md:data-[motion=to-start]:animate-exit-to-left',
    nestedList: 'mb-4 mt-1 grid gap-1 md:m-0 md:w-[500px] md:grid-flow-col md:grid-rows-2 md:p-4 lg:w-[580px]',
    subLink:
      'text-decoration-none relative block rounded pr-2 md:p-4 [&_*]:relative [&_*]:z-2 [&_.sub-link-bg]:hover:scale-100 [&_.sub-link-bg]:hover:opacity-100',
    subLinkBg:
      'sub-link-bg !absolute bottom-0 left-0 right-0 top-0 !z-1 block scale-90 rounded bg-grey-100 opacity-0 transition-all duration-100 ease-in-out',
    indicatorWrapper:
      'data[state=hidden]:animate-fade-out absolute top-[calc(100%-2px)] z-2 flex h-[10px] items-end justify-center overflow-hidden transition-[width,transform_250ms_ease] data-[state=visible]:animate-fade-in',
    indicator: 'relative top-[70%] h-[10px] w-[10px] rotate-45 transform rounded-tl-[2px] bg-white',
    navViewportPosition:
      'absolute absolute left-0 top-[calc(100%+8px)] flex w-full justify-center perspective-[2000px]',
    navViewport:
      'relative h-[var(--radix-navigation-menu-viewport-height)] w-full origin-center origin-top overflow-hidden rounded-md bg-white shadow-lg transition-[width,height_300ms_ease] data-[state=closed]:animate-scale-out data-[state=open]:animate-scale-in sm:w-[var(--radix-navigation-menu-viewport-width)]',
    icon: 'down-icon ml-1 transition-all',
    navLink:
      'text-decoration-none text-inherit relative flex w-full justify-between font-medium leading-tight max-md:py-0 max-md:py-2 md:p-2 md:text-baseSm [&_.down-icon]:data-[state=open]:rotate-180 [&_.nav-link-bg]:hover:scale-[1.02] [&_.nav-link-bg]:hover:opacity-100 [&_.nav-link-bg]:data-[state=open]:scale-[1.02] [&_.nav-link-bg]:data-[state=open]:opacity-100',
    navLinkInner: 'relative z-2 flex w-full items-center justify-between truncate',
    navLinkBg:
      'nav-link-bg pointer-events-none absolute bottom-0 left-0 right-0 top-0 block scale-90 rounded-full opacity-0 transition-all duration-100 ease-in-out max-md:hidden',
  },
  variants: {
    variant: {
      light: {
        navLink: 'text-blue-700 md:text-white',
        navMenuParentListItemDesktop: 'text-blue-700 md:text-white',
        icon: '[&_svg]:fill-white',
        logoLink: 'md:[&_svg_path]:fill-white',
        navLinkBg: 'bg-blue-700',
      },
      dark: {
        navLink: 'text-blue-700',
        navMenuParentListItemDesktop: 'text-blue-700 md:text-white',
        icon: '[&_svg]:fill-blue-700',
        logoLink: 'md:[&_svg_path]:fill-blue-700',
        navLinkBg: 'bg-[#d5e4ec]',
        navViewport: 'border border-grey-200',
      },
    },
    navIsOpen: {
      true: {
        navMenuRoot: 'max-md:translate-y-0',
        navMenuList: 'max-md:opacity-1 max-md:delay-300',
      },
      false: null,
    },
    navLinkIsActive: {
      true: {
        navLinkBg: 'scale-[1.02] opacity-100',
      },
    },
    subLinkIsActive: {
      true: {
        subLink: 'max-md:[&_span:first-of-type]:underline',
        subLinkBg: 'scale-100 opacity-100',
      },
    },
  },
  compoundVariants: [
    {
      navIsOpen: false,
      variant: 'light',
      class: 'max-md:[&_.logo-link_svg_path]:fill-white',
    },
  ],
});

export { PageHeader };
