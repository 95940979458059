import { themeGet } from '@styled-system/theme-get';
import { format } from 'date-fns';
import Image from 'next/image';
import styled from 'styled-components';

import { Container } from '@/components/container';
import { Heading } from '@/components/heading';
import { Icon } from '@/components/icon';
import { Link } from '@/components/link';
import { Logo } from '@/components/logo';
import { Text } from '@/components/text';
import { VisuallyHidden } from '@/components/visually-hidden';
import { SITE_NAME } from '@/lib/constants';
import { footerNav, marketingPages } from '@/lib/constants/marketing-site';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

const PageFooter = () => {
  const { heading, navContainer, navLink, legals, iconList } = footerStyles();

  // Click handler for event tracking
  const handleSocialLinkClick = (label: string) => {
    trackUser.conversion({ category: 'FooterLinks', action: `Click${label}` });
  };

  return (
    <footer className="bg-[#1b1f23]">
      <Container className={navContainer()}>
        <div>
          <Heading as="h5" className={heading()}>
            Product
          </Heading>
          <nav>
            {footerNav.product.map((item) => {
              return (
                <Link key={item.url} href={item.url} prefetch={false} className={navLink()}>
                  {item.label}
                </Link>
              );
            })}
          </nav>
        </div>
        <div>
          <Heading as="h5" className={heading()}>
            Tools
          </Heading>
          <nav>
            {footerNav.tools.map((item) => {
              return (
                <Link key={item.url} href={item.url} prefetch={false} className={navLink()}>
                  {item.label}
                </Link>
              );
            })}
          </nav>
        </div>
        <div>
          <Heading as="h5" className={heading()}>
            Company
          </Heading>
          <nav>
            {footerNav.company.map((item) => {
              return (
                <Link key={item.url} href={item.url} prefetch={false} className={navLink()}>
                  {item.label}
                </Link>
              );
            })}
          </nav>
        </div>
        <div>
          <Heading as="h5" className={heading()}>
            Resources
          </Heading>
          <nav>
            {footerNav.resources.map((item) => {
              return (
                <Link key={item.url} href={item.url} prefetch={false} className={navLink()}>
                  {item.label}
                </Link>
              );
            })}
          </nav>
        </div>
        <div>
          <Link href="/">
            <Logo mr="lg" variant="light" width="144px" />
          </Link>
          <Text className="mb-0 mt-6 text-baseSm leading-snug text-white opacity-50">
            At Fluent Cargo, our mission is to create the world&apos;s most comprehensive shipment planning tools for
            those in global trade.
          </Text>
          <ul className={iconList()}>
            {footerNav.social.map((item, i) => (
              <li key={`footer-nav-item-${i}`}>
                <Link href={item.url} onClick={() => handleSocialLinkClick(item.label)} target="_blank">
                  <Icon name={item.icon} color="white" />
                  <VisuallyHidden>{item.label}</VisuallyHidden>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Container>
      <Container>
        <div className={legals()}>
          <div className="flex items-center">
            <Image width={32} height={32} src="/favicon-white.png" alt={SITE_NAME} />
            <LegalText className="mb-4 ml-0 md:mb-0 md:ml-4" variant="secondary">
              <Link href={marketingPages.ports.url}>{marketingPages.ports.label}</Link> /{' '}
              <Link href={marketingPages.carriers.url}>{marketingPages.carriers.label}</Link>
            </LegalText>
          </div>
          <LegalText variant="secondary" className="mb-0">
            Copyright &copy; {format(new Date(), 'Y')} {SITE_NAME}.{' '}
            <Link href={marketingPages.terms.url}>{marketingPages.terms.label}</Link> /{' '}
            <Link href={marketingPages.privacy.url}>{marketingPages.privacy.label}</Link>
          </LegalText>
        </div>
      </Container>
    </footer>
  );
};

const footerStyles = tv({
  slots: {
    heading: 'text-baseSm font-medium tracking-[0.1rem] text-white lg:text-baseSm',
    navContainer:
      'grid grid-cols-2 gap-x-4 gap-y-8 py-12 text-white md:grid-cols-5 md:gap-4 md:py-20 [&>:last-child]:max-md:col-start-1 [&>:last-child]:max-md:col-end-3 [&>:last-child]:max-md:flex [&>:last-child]:max-md:flex-col',
    navLink: 'decoration-none relative mt-4 block cursor-pointer text-baseSm text-white opacity-50 hover:opacity-100',
    legals:
      'border-t border-white border-opacity-20 py-6 text-white text-opacity-50 md:flex md:items-center md:justify-between [&_img]:max-md:hidden',
    iconList: 'mt-6 flex list-none items-center gap-4 p-0 [&_svg]:h-5 [&_svg]:w-auto',
  },
});

const LegalText = styled(Text)`
  font-size: ${themeGet('fontSizes.xs')};
  color: ${themeGet('colors.white')};
  opacity: 0.5;
`;

export { PageFooter };
